<template>
  <section class="dashboard section is-full-touch is-full mobile">
    <div class="container is-fluid-desktop is-full-touch is-full-mobile">
      <div class="is-hidden-desktop is-hidden-widescreen">
        <Mobilemenu />
      </div>
      <div class="columns">
        <div class="column is-narrow is-hidden-mobile sbar">
          <Sidebar />
        </div>
        <div class="column is-full-mobile">
          <div class="columns">
            <div class="column is-half-desktop is-hidden-touch">
              <h1 class="title">My Account</h1>
              <h2 class="subtitle">
                You can find your account details here
              </h2>
            </div>
            <div
              class="column is-half is-flex is-justify-content-space-between is-align-items-baseline"
            >
<!--              <article class="message is-primary">-->
<!--                <div class="message-body">-->
<!--                  <p>-->
<!--                    USDT Option is available please send a message to-->
<!--                    Rektproof#1553 on Discord for details-->
<!--                  </p>-->
<!--                </div>-->
<!--              </article>-->
              <button class="button is-text" @click="disclaimer = true">
                Disclaimer
              </button>
            </div>
          </div>

<!--          <div v-if="usdtDeposit" class="card block">-->
<!--            <header class="card-header">-->
<!--              <p class="card-header-title">-->
<!--                Create Invoice-->
<!--              </p>-->
<!--            </header>-->
<!--            <div class="card-content">-->
<!--              <div class="columns payment">-->
<!--                <div class="column is-flex is-align-items-center">-->
<!--                  <div class="content">-->
<!--                    <label class="label">Enter the amount of USDT you want to deposit:</label>-->
<!--                    <div class="field has-addons">-->
<!--                      <p class="control">-->
<!--                        <input v-model.number="usdtDepositAmount" class="input" type="number" min="1" placeholder="amount">-->
<!--                      </p>-->
<!--                      <p class="control">-->
<!--                        <a class="button is-static">-->
<!--                          USDT-->
<!--                        </a>-->
<!--                      </p>-->
<!--                    </div>-->
<!--                    <div>-->
<!--                      <button @click="usdtDeposit = false" class="button is-light mr-4">-->
<!--                        Cancel-->
<!--                      </button>-->
<!--                      <button-->
<!--                          @click="createUsdtPaymentRequest"-->
<!--                          class="button is-danger"-->
<!--                          :class="{ 'is-loading': createUsdtPaymentRequestLoading }"-->
<!--                      >Continue</button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->

          <div v-if="invoice && invoiceTimerCount > 0" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                Invoice
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <div class="column is-one-third">
                  <div class="content">
                    <img :src="qrUsdt" alt="payment qr code" />
                  </div>
                </div>
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p>{{ invoice.payments[0].payment_address }}</p>
                    <p>
                      Please send <strong>EXACTLY</strong> {{ invoice.payments[0].amount }} {{ invoice.payments[0].name }} + fees to the above address.
                    </p>
                    <p class="has-text-danger">
                      Chain: ERC20
                    </p>
                    <p>Time left: {{ invoiceTimerCount | minutes }}</p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after 15 confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="paymentUsdt" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                {{ selectedPlan.name }}
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <!--                <div class="column is-one-third">-->
                <!--                  <div class="content">-->
                <!--                    <img :src="qr" alt="payment qr code" />-->
                <!--                  </div>-->
                <!--                </div>-->
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p class="has-text-danger">
                      Chain: ERC20 (Ethereum) only
                    </p>
                    <p>ERC20 Address:</p>
                    <p>{{ user.blockbee_erc20_usdt }}</p>
                    <p>Minimum deposit: 10 USDT</p>
                    <p>
                      Please send {{ usdtCost }} USDT for the {{ selectedPlan.name }}
                    </p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after a few confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="paymentTrc20" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                {{ selectedPlan.name }}
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p class="has-text-danger">
                      Chain: TRC20 (Tron) only
                    </p>
                    <p>TRC20 Address:</p>
                    <p>{{ user.blockbee_trc20_usdt }}</p>
                    <p>Minimum deposit: 5 USDT / 1 USDC</p>
                    <p>
                      Please send {{ usdtCost }} USDT or USDC for the {{ selectedPlan.name }}
                    </p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after a few confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="paymentBep20" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                {{ selectedPlan.name }}
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p class="has-text-danger">
                      Chain: BEP20 (BSC - Binance Smart Chain) only
                    </p>
                    <p>BEP20 Address:</p>
                    <p>{{ user.blockbee_bep20_usdt }}</p>
                    <p>Minimum deposit: 1 USDT / 1 USDC</p>
                    <p>
                      Please send {{ usdtCost }} USDT or USDC for the {{ selectedPlan.name }}
                    </p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after a few confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="paymentPolygon" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                {{ selectedPlan.name }}
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p class="has-text-danger">
                      Chain: Polygon only
                    </p>
                    <p>Polygon Address:</p>
                    <p>{{ user.blockbee_polygon_usdt }}</p>
                    <p>Minimum deposit: 0.5 USDT / 0.5 USDC</p>
                    <p>
                      Please send {{ usdtCost }} USDT or USDC for the {{ selectedPlan.name }}
                    </p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after a few confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="payment && timerCount > 0" class="card block">
            <header class="card-header">
              <p class="card-header-title">
                {{ selectedPlan.name }}
              </p>
            </header>
            <div class="card-content">
              <div class="columns payment">
                <div class="column is-one-third">
                  <div class="content">
                    <img :src="qr" alt="payment qr code" />
                  </div>
                </div>
                <div class="column is-flex is-align-items-center">
                  <div class="content">
                    <p>{{ payment.address }}</p>
                    <p>
                      Please send {{ cost }} BTC for the {{ selectedPlan.name }}
                    </p>
                    <p>Time left: {{ timerCount | minutes }}</p>
                    <p class="has-text-weight-semibold">
                      Note: your sub won't activate automatically. Your deposit
                      is credited to your balance after 3 confirmations.
                      Remember to come back to this page and activate the plan
                      you want with your balance, then click the Join button to
                      join the Discord server.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns account">
            <div class="column">
              <article v-if="user" class="card account block">
                <div class="card-content">
                  <div class="deposit">
                    <p class="has-text-weight-bold subtitle is-6">
                      Balance:
                      <span class="has-text-success"
                        >${{ user.balance | fiat }}</span
                      >
                    </p>
                    <div class="is-flex deposit-buttons">
                      <button class="button is-success is-small" @click="depositModal = true">
                        Deposit
                      </button>
<!--                      <template v-else>-->
<!--                        <button-->
<!--                            @click="createPaymentRequest"-->
<!--                            class="button is-danger is-small"-->
<!--                            :class="{ 'is-loading': createPaymentRequestLoading }"-->
<!--                        >-->
<!--                          <template v-if="createPaymentRequestLoading"-->
<!--                          >Loading</template-->
<!--                          >-->
<!--                          <template v-else>Deposit BTC</template>-->
<!--                        </button>-->
<!--                        <button-->
<!--                            @click="blockbeeUsdt"-->
<!--                            class="button is-danger is-small"-->
<!--                            :class="{ 'is-loading': blockbeeAddressLoading }"-->
<!--                        >Deposit USDT</button>-->
<!--                      </template>-->
                    </div>
<!--                    <button-->
<!--                      @click="createPaymentRequest"-->
<!--                      class="button is-danger is-small"-->
<!--                      :class="{ 'is-loading': createPaymentRequestLoading }"-->
<!--                    >-->
<!--                      <template v-if="createPaymentRequestLoading"-->
<!--                        >Loading</template-->
<!--                      >-->
<!--                      <template v-else>Deposit</template>-->
<!--                    </button>-->
                  </div>
                  <p class="has-text-weight-bold subtitle is-6">
                    Subscription:
                    <span v-if="noSub" class="has-text-danger-dark"
                      >inactive</span
                    >
                    <span v-if="activeSub" class="has-text-success "
                      >ends on {{ subDate }}</span
                    >
                    <span v-if="expiredSub" class="has-text-danger-dark"
                      >expired on {{ subDate }}</span
                    >
                  </p>
                  <div
                    v-if="activeSub"
                    class="is-flex is-justify-content-space-between has-text-weight-bold subtitle is-6"
                  >
                    <div class="is-flex is-flex-direction-column" v-if="!whopUser">
                      Discord:
<!--                      <span v-if="user.discord_username">{{ user.discord_username }}</span>-->
                    </div>
                    <div v-if="!whopUser">
                      <a :href="discordAuthUrl" class="button is-primary is-small"
                      >Join</a
                      >
                      <button v-if="user.discord_id"  @click="unlinkDiscord" class="button is-black is-small ml-4" v-b-tooltip.hover title="Disconnects the Discord account that is currently connected to the HighPoint PA website. Can be used to connect a different Discord account, or when the member role is not being granted after a renewal of the subscription (disconnect the account, reconnect and rejoin the Discord).">
                        <span>Reset</span>
                        <span class="icon is-small">
                          <i class="fas fa-info-circle"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    v-else
                    @click="showPaymentRequiredModal()"
                    class="is-flex is-justify-content-space-between has-text-weight-bold subtitle is-6"
                  >
                    <span v-if="!whopUser">Discord:</span>
                    <button v-if="!whopUser" class="button is-warning is-small">Join</button>
                  </div>
                  <div
                      v-if="activeSub && !whopUser"
                      class="is-flex is-justify-content-space-between has-text-weight-bold subtitle is-6"
                  >
                    <div class="is-flex is-flex-direction-column">
                      Telegram (HighPoint):
                      <span v-if="telegramInvite && user.telegram_name">{{ user.telegram_name }}</span>
                    </div>
                    <template v-if="!user.telegram_id">
                      <vue-telegram-login
                          mode="callback"
                          telegram-login="RektproofPA_bot"
                          @callback="telegramLoginCallback"
                          size="medium"
                          requestAccess="write"
                      />
                    </template>
                    <template v-if="user.telegram_id">
                      <template v-if="telegramInvite">
                        <a :href="telegramInvite.invite_link" class="button is-light is-small" target="_blank"
                        >Use invite</a
                        >
                      </template>
                      <template v-else>
                        <button @click="joinTelegram" class="button is-light is-small" :class="{ 'is-loading': getTelegramInviteLoading }">Generate invite</button>
                      </template>
                    </template>
                  </div>
<!--                  <div-->
<!--                    v-if="activeSub"-->
<!--                    class="is-flex is-justify-content-space-between has-text-weight-bold subtitle is-6"-->
<!--                  >-->
<!--                    <div class="is-flex is-flex-direction-column">-->
<!--                      Telegram (Trader Koz):-->
<!--                    </div>-->
<!--                    <template v-if="user.telegram_id">-->
<!--                      <template v-if="telegramInviteKozTrader">-->
<!--                        <a :href="telegramInviteKozTrader.invite_link" class="button is-primary is-small" target="_blank"-->
<!--                        >Use invite</a-->
<!--                        >-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <button @click="joinTelegramKozTrader" class="button is-primary is-small" :class="{ 'is-loading': getTelegramInviteKozTraderLoading }">Generate invite</button>-->
<!--                      </template>-->
<!--                    </template>-->
<!--                  </div>-->
                  <div
                      v-if="activeSub && !whopUser"
                      class="is-flex is-justify-content-space-between has-text-weight-bold subtitle is-6"
                  >
                    <div class="is-flex is-flex-direction-column">
                      Telegram (Rekt Trader):
                    </div>
                    <template v-if="user.telegram_id">
                      <template v-if="telegramInviteRektTrader">
                        <a :href="telegramInviteRektTrader.invite_link" class="button is-light is-small" target="_blank"
                        >Use invite</a
                        >
                      </template>
                      <template v-else>
                        <button @click="joinTelegramRektTrader" class="button is-light is-small" :class="{ 'is-loading': getTelegramInviteRektTraderLoading }">Generate invite</button>
                      </template>
                    </template>
                  </div>
                  <p class="has-text-weight-bold subtitle is-6">
                    User details:
                    <span class="userEmail"
                      >{{ currentUserEmail }}
                      <button
                        @click="resetPassword = true"
                        class="button is-black is-small"
                      >
                        Change password
                      </button>
                    </span>
                  </p>
                </div>
              </article>
            </div>
            <div class="column">
              <div v-for="plan in orderedPlans" :key="plan.id" class="block">
                <div
                  :class="{
                    selected:
                      orderedPlans.length === 1 || selectedPlan.id === plan.id,
                  }"
                  class="card"
                  @click="togglePlan(plan)"
                >
                  <header class="card-header">
                    <p class="card-header-title">
                      {{ plan.name }}
                    </p>
                    <button
                      aria-label="more options"
                      class="card-header-icon plan"
                    >
                      <span class="icon">
                        <i aria-hidden="true" class="fas fa-angle-down"></i>
                      </span>
                    </button>
                  </header>
                  <div
                    v-show="
                      orderedPlans.length === 1 || expandedPlan === plan.id
                    "
                    class="card-content"
                  >
                    <div class="content">
                      <ul>
                        <li>HighPoint Discord Community</li>
                        <li>
                          HighPoint's Educational content/course with
                          back-tested strategies
                        </li>
                        <li>Daily Analysis and market overview</li>
                        <li>
                          Individual trade journal and knowledge on trade
                          management
                        </li>
                        <li>Trade setups reflected in real time</li>
                      </ul>
                      <div
                        class="is-flex is-justify-content-space-between is-align-items-center"
                      >
                        <template v-if="discountEnabled">
                          <span class="has-text-weight-bold is-size-5"
                          ><s>${{ plan.price }}</s> ${{ discountedPrice(plan) }} / {{ plan.duration }} days</span
                          >
                        </template>
                        <template v-else>
                          <span class="has-text-weight-bold is-size-5"
                          >${{ plan.price }} / {{ plan.duration }} days</span
                          >
                        </template>
                        <button
                          v-if="user.balance >= discountedPrice(plan)"
                          class="button is-primary"
                          :class="{ 'is-loading': activateSubLoading }"
                          :disabled="activateSubLoading"
                          @click="activateSubscription(plan)"
                        >
                          Subscribe
                        </button>
                        <button
                          v-else
                          class="button is-primary"
                          @click="depositModal = true"
                        >
                          <template v-if="createPaymentRequestLoading"
                            >Loading...</template
                          >
                          <template v-else>Subscribe</template>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <!-- Plain HTML Announcement Card -->
            </div>
          </div>
          <div v-if="invoices.length" class="card">
            <div class="card-content">
              <div>
                <h2 class="title">Invoices</h2>
                <table
                    id="invoices"
                    class="table is-fullwidth is-hoverable"
                >
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(invoice) in orderedInvoices" :key="invoice.id">
                    <td>{{ invoice.creation.toDate().toLocaleString() }}</td>
                    <td>{{ invoice.amount }} {{ invoice.currency }}</td>
                    <td>{{ invoice.status }}</td>
                    <td>
                      <button v-if="invoice.status === 'pending'" @click="selectInvoice(invoice)" class="button is-small">View</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="transactions.length" class="card">
            <div class="card-content">
              <div v-if="!isMobile()">
                <h2 class="title">Transactions</h2>
                <table
                  id="transactions"
                  class="table is-fullwidth is-hoverable"
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Paid</th>
                      <th>Rate</th>
                      <th>Confirmations</th>
                      <th>Status</th>
                      <th>Hash</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tx) in orderedTransactions" :key="tx.id">
                      <td>{{ tx.date.toDate().toLocaleString() }}</td>
                      <td>${{ tx.paid | fiat }}</td>
                      <td>${{ tx.rate }}</td>
                      <td>{{ tx.confirmations }} / 3</td>
                      <td>{{ tx.status }}</td>
                      <td>
                        <a
                          :href="
                            'https://live.blockcypher.com/btc/tx/' +
                              tx.hash
                          "
                          target="_blank"
                          >Explore</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <h2 class="title">Transactions</h2>
                <table
                  id="transactions"
                  class="table is-fullwidth is-hoverable"
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Rate</th>
                      <th>Status</th>
                      <th>Hash</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tx) in orderedTransactions" :key="tx.id">
                      <td>{{ tx.date.toDate().toLocaleString() }}</td>
                      <td>{{ tx.status }}</td>
                      <td>
                        <a
                          :href="
                            'https://live.blockcypher.com/btc/tx/' +
                              tx.hash
                          "
                          target="_blank"
                          >Explore</a
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
<!--    <DepositChooseChainModal :active="depositModal" @close="depositModal = false" />-->
    <div :class="{ 'is-active': depositModal }" class="modal">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Deposit</p>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <h4 class="title is-4">Choose coin to deposit</h4>
            <div class="buttons">
            <button
              @click="createPaymentRequest"
              class="button is-warning"
              :class="{ 'is-loading': createPaymentRequestLoading }"
            >
              <template v-if="createPaymentRequestLoading"
                >Loading...</template
              >
              <template v-else>Bitcoin</template>
            </button>
              <button @click="depositCoinSelected = 'USDT'" class="button is-success">USDT</button>
              <button @click="depositCoinSelected = 'USDC'" class="button is-primary">USDC</button>
            </div>

            <template v-if="stablecoinDeposit">
              <h4 class="title is-4">Choose a Chain for {{ depositCoinSelected }}</h4>
              <div class="buttons">
                <button
                    v-if="depositCoinSelected == 'USDT'"
                    @click="blockbeeUsdt"
                    class="button is-black"
                    :class="{ 'is-loading': blockbeeAddressLoading }"
                >
                  <template v-if="blockbeeAddressLoading"
                  >Loading...</template
                  >
                  <template v-else>ERC20</template>
                </button>
                <button
                    @click="blockbeeTrc20"
                    class="button is-black"
                    :class="{ 'is-loading': blockbeeTrcAddressLoading }"
                >
                  <template v-if="blockbeeTrcAddressLoading"
                  >Loading...</template
                  >
                  <template v-else>TRC20</template>
                </button>
                <button
                    @click="blockbeeBep20"
                    class="button is-black"
                    :class="{ 'is-loading': blockbeeBepAddressLoading }"
                >
                  <template v-if="blockbeeBepAddressLoading"
                  >Loading...</template
                  >
                  <template v-else>BSC (BEP20)</template>
                </button>
                <button
                    @click="blockbeePolygon"
                    class="button is-black"
                    :class="{ 'is-loading': blockbeePolygonAddressLoading }"
                >
                  <template v-if="blockbeePolygonAddressLoading"
                  >Loading...</template
                  >
                  <template v-else>Polygon</template>
                </button>
              </div>
            </template>
            <p class="has-text-weight-semibold">Rather pay with a credit card? Visit our Whop store: <a href="https://whop.com/rektproof" target="_blank">here</a></p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-dark" @click="closeDepositModal">Cancel</button>
        </footer>
      </div>
    </div>
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
    <PaymentRequiredModal
      :active="paymentRequired"
      @close="paymentRequired = false"
    />
    <PasswordResetMyAccount
      :active="resetPassword"
      @close="resetPassword = false"
    />
  </section>
</template>

<script>
import Sidebar from "@/components/Sidebar2.vue";
import Mobilemenu from "@/components/Mobilemenu2.vue";
import { auth, db } from "@/firebase";
import AccountService from "../services/AccountService";
import DisclaimerModal from "@/components/DisclaimerModal";
import PaymentRequiredModal from "@/components/PaymentRequiredModal";
import PasswordResetMyAccount from "../components/PasswordResetMyAccount";
import { vueTelegramLogin } from 'vue-telegram-login'

export default {
  name: "AccountMyAccountView",
  components: {
    Sidebar,
    DisclaimerModal,
    PaymentRequiredModal,
    Mobilemenu,
    PasswordResetMyAccount,
    vueTelegramLogin
  },
  data() {
    return {
      admin: false,
      user: {
        balance: 0,
        subscription_end_date: null,
        discord_id: null,
      },
      transactions: [],
      invoices: [],
      payment: null,
      plans: [],
      discount: null,
      selectedPlan: {
        id: null,
      },
      expandedPlan: null,
      timerCount: 0,
      invoice: null,
      invoiceTimerCount: 0,
      disclaimer: false,
      depositModal: false,
      depositCoinSelected: null,
      depositChainSelected: null,
      depositType: null,
      paymentRequired: false,
      resetPassword: false,
      createPaymentRequestLoading: false,
      createUsdtPaymentRequestLoading: false,
      activateSubLoading: false,
      getTelegramInviteLoading: false,
      getTelegramInviteRektTraderLoading: false,
      getTelegramInviteKozTraderLoading: false,
      telegramInvite: null,
      telegramInvites: [],
      telegramInviteRektTrader: null,
      telegramInviteKozTrader: null,
      usdtDeposit: false,
      usdtDepositAmount: 10,
      paymentUsdt: false,
      blockbeeAddressLoading: false,
      paymentTrc20: false,
      blockbeeTrcAddressLoading: false,
      paymentBep20: false,
      blockbeeBepAddressLoading: false,
      paymentPolygon: false,
      blockbeePolygonAddressLoading: false,
    };
  },
  firestore: {
    // plans: db.collection("plans"),
  },
  async created() {
    const idTokenResult = await auth.currentUser.getIdTokenResult();
    // Confirm the user is an Admin.
    this.admin = !!idTokenResult.claims.admin;
    if (this.$route.query.code) {
      console.log("code", this.$route.query.code);
      await AccountService.linkDiscord(this.$route.query.code);
      // console.log(res);
    } else {
      // console.log("no code");
    }
    this.$bind(
      "user",
      db.collection("users").doc(this.$store.state.userProfile.uid)
    ).then((user) => {
      if (!user) {
        alert('User not found');
        this.$store.dispatch("logout");
      }
    });
    this.$bind(
        "invoices",
        db
            .collection("invoices")
            .where("user", "==", this.$store.state.userProfile.uid)
    ).then((invoices) => {
      this.invoices = invoices;
    });
    this.$bind(
      "transactions",
      db
        .collection("transactions")
        .where("user", "==", this.$store.state.userProfile.uid)
    ).then((transactions) => {
      this.transactions = transactions;
    });
    this.$bind(
        "plans",
        db.collection("plans")
    ).then((plans) => {
      this.selectedPlan = plans[1];
      this.expandedPlan = this.selectedPlan.id;
    });
    this.$bind(
        "discount",
        db.collection("settings").doc("discount")
    ).then((discount) => {
      this.discount = discount
    });
    this.$bind(
        "telegramInvites",
        db.collection("telegram_invites")
        .where("user_id", "==", this.$store.state.userProfile.uid)
    ).then((invites) => {
      if (invites && invites.length > 0) {
        this.telegramInvite = invites.find((invite) => invite.chat_id === this.$store.state.telegram_chat_id);
        this.telegramInviteRektTrader = invites.find((invite) => invite.chat_id === this.$store.state.telegram_rekt_traders_chat_id);
        this.telegramInviteKozTrader = invites.find((invite) => invite.chat_id === this.$store.state.telegram_koz_trader_chat_id);
      }
    });
    // this.$bind(
    //     "telegramInvite",
    //     db.collection("telegram_invites")
    //     .where("user_id", "==", this.$store.state.userProfile.uid)
    //     .where("telegram_chat_id", "==", this.$store.state.telegram_chat_id)
    // ).then((invite) => {
    //   if (invite && invite.length > 0) {
    //     this.telegramInvite = invite[0];
    //   } else {
    //     this.telegramInvite = null;
    //   }
    // });
    // this.$bind(
    //     "telegramInviteRektTrader",
    //     db.collection("telegram_invites")
    //     .where("user_id", "==", this.$store.state.userProfile.uid)
    //     .where("telegram_chat_id", "==", this.$store.state.telegram_rekt_traders_chat_id)
    // ).then((invite) => {
    //   if (invite && invite.length > 0) {
    //     this.telegramInvite = invite[0];
    //   } else {
    //     this.telegramInvite = null;
    //   }
    // });
  },
  filters: {
    minutes: function(value) {
      if (!value) return "0 minutes";
      return `${(value / 60).toFixed(0)} minutes`;
    },
    fiat: function(value) {
      if (!value) return 0;
      return value.toFixed(2);
    },
    // discountedPrice: function(value) {
    //   if (!this.discount) return value;
    //   if (!value) return 0;
    //   return value - (value * (this.discount?.discountPercent / 100))
    // },
  },
  computed: {
    stablecoinDeposit() {
      return ["USDT", "USDC"].includes(this.depositCoinSelected)
    },
    discordAuthUrl() {
      const client_id = process.env.VUE_APP_DISCORD_CLIENT_ID;
      const redirect_uri = encodeURIComponent(
        process.env.VUE_APP_DISCORD_REDIRECT_URI
      );
      const scope = encodeURI("identify email guilds.join");
      return `https://discord.com/api/oauth2/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${scope}`;
    },
    orderedPlans() {
      return this.plans
        .filter((x) => x.status === "active")
        .sort((a, b) => (a.price < b.price ? 1 : -1));
    },
    discountEnabled() {
      return !!(this.discount?.enabled && this.user?.discount);
    },
    activeSub() {
      return this.user.subscription_end_date?.toDate() > new Date();
    },
    expiredSub() {
      return this.user.subscription_end_date?.toDate() < new Date();
    },
    noSub() {
      return !this.user.subscription_end_date;
    },
    subDate() {
      return this.user.subscription_end_date?.toDate().toLocaleString();
    },
    whopUser() {
      return this.user.whopuser;
    },
    currentUserEmail() {
      return this.user.email;
    },
    qr() {
      return `https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=bitcoin:${this.payment.address}?amount=${this.cost}`;
    },
    qrUsdt() {
      return `https://chart.googleapis.com/chart?chs=225x225&chld=L|2&cht=qr&chl=${this.invoice.payments[0].payment_url}`;
    },
    // thePlan() {
    //   if (this.selectedPlan && this.user && this.payment) {
    //     const btc =
    //         (this.selectedPlan.price - this.user.balance) / this.payment.rate;
    //     const round = 0.000000005;
    //     return (btc + round).toFixed(8);
    //   } else {
    //     return 0;
    //   }
    // },
    cost() {
      if (this.selectedPlan && this.user && this.payment) {
        let price = this.selectedPlan.price;
        if (this.discountEnabled) {
          price = price - (price * (this.discount.discountPercent / 100))
        }
        if (this.user.balance < price) {
          const btc =
              (price - this.user.balance) / this.payment.rate;
          const round = 0.000000005;
          return (btc + round).toFixed(8);
        } else {
          const btc =
              (price) / this.payment.rate;
          const round = 0.000000005;
          return (btc + round).toFixed(8);
        }
      } else {
        return 0;
      }
    },
    usdtCost() {
      if (this.selectedPlan && this.user) {
        let price = this.selectedPlan.price;
        if (this.discountEnabled) {
          price = price - (price * (this.discount.discountPercent / 100))
        }
        const cost = price - this.user.balance
        if (cost > 0) {
          return cost;
        } else {
          return price;
        }
      } else {
        return 0;
      }
    },
    orderedInvoices() {
      return this.invoices.sort((a, b) => (a.creation.seconds > b.creation.seconds ? -1 : 1));
    },
    orderedTransactions() {
      return this.transactions.sort((a, b) => (a.date.seconds > b.date.seconds ? -1 : 1));
    },
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else {
          this.payment = null;
        }
      },
      //immediate: true // This ensures the watcher is triggered upon creation
    },
    invoiceTimerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.invoiceTimerCount--;
          }, 1000);
        } else {
          this.invoice = null;
        }
      },
      //immediate: true // This ensures the watcher is triggered upon creation
    },
  },
  methods: {
    async unlinkDiscord() {
      await AccountService.unlinkDiscord();
    },
    async telegramLoginCallback (user) {
      // gets user as an input
      // id, first_name, last_name, username,
      // photo_url, auth_date and hash
      console.log(user)
      try {
        this.getTelegramInviteLoading = true;
        let telegram_name = user.username || `${user.first_name} ${user?.last_name}`
        telegram_name = telegram_name.replace("undefined", "").trim()
        const res = await AccountService.linkTelegram(user.id, telegram_name);
        console.log(res);
        if (res.data.data.invite_link) {
          window.open(res.data.data.invite_link, '_blank');
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.getTelegramInviteLoading = false;
      }
    },
    async joinTelegram() {
      try {
        this.getTelegramInviteLoading = true;
        const res = await AccountService.getTelegramInviteLink();
        console.log(res);
        // if (res.data.data.invite_link) {
        //   window.open(res.data.data.invite_link, '_blank');
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.getTelegramInviteLoading = false;
      }
    },
    async joinTelegramKozTrader() {
      try {
        this.getTelegramInviteKozTraderLoading = true;
        const res = await AccountService.getTelegramInviteLinkKozTrader();
        console.log(res);
        // if (res.data.data.invite_link) {
        //   window.open(res.data.data.invite_link, '_blank');
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.getTelegramInviteKozTraderLoading = false;
      }
    },
    async joinTelegramRektTrader() {
      try {
        this.getTelegramInviteRektTraderLoading = true;
        const res = await AccountService.getTelegramInviteLinkRektTrader();
        console.log(res);
        // if (res.data.data.invite_link) {
        //   window.open(res.data.data.invite_link, '_blank');
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.getTelegramInviteRektTraderLoading = false;
      }
    },
    closeDepositModal() {
      this.depositCoinSelected = null
      this.depositModal = false
    },
    async createPaymentRequest() {
      try {
        this.createPaymentRequestLoading = true;
        const res = await AccountService.createPaymentRequest();
        console.log(res);
        this.payment = res.data.data.payment;
        this.timerCount = this.payment.expires._seconds - Date.now() / 1000;
        this.closeDepositModal()
        this.paymentUsdt = false
        this.paymentPolygon = false
        this.paymentTrc20 = false
        this.paymentBep20 = false
      } catch (e) {
        console.log(e);
      } finally {
        this.createPaymentRequestLoading = false;
      }
    },
    async blockbeeUsdt() {
      try {
        this.blockbeeAddressLoading = true;
        if (!this.user.blockbee_erc20_usdt) {
          const res = await AccountService.createBlockbeeAddress();
          console.log(res)
        }
        this.paymentUsdt = true;
        this.closeDepositModal()
        this.payment = false
        this.paymentPolygon = false
        this.paymentTrc20 = false
        this.paymentBep20 = false
      } catch (e) {
        console.log(e);
      } finally {
        this.blockbeeAddressLoading = false;
      }
    },
    async blockbeeTrc20() {
      try {
        this.blockbeeTrcAddressLoading = true;
        if (!this.user.blockbee_trc20_usdt) {
          const res = await AccountService.createBlockbeeAddressTrc();
          console.log(res)
        }
        this.paymentTrc20 = true;
        this.closeDepositModal()
        this.payment = false
        this.paymentUsdt = false
        this.paymentPolygon = false
        this.paymentBep20 = false
      } catch (e) {
        console.log(e);
      } finally {
        this.blockbeeTrcAddressLoading = false;
      }
    },
    async blockbeeBep20() {
      try {
        this.blockbeeBepAddressLoading = true;
        if (!this.user.blockbee_bep20_usdt) {
          const res = await AccountService.createBlockbeeAddressBep();
          console.log(res)
        }
        this.paymentBep20 = true;
        this.closeDepositModal()
        this.payment = false
        this.paymentUsdt = false
        this.paymentPolygon = false
        this.paymentTrc20 = false
      } catch (e) {
        console.log(e);
      } finally {
        this.blockbeeBepAddressLoading = false;
      }
    },
    async blockbeePolygon() {
      try {
        this.blockbeePolygonAddressLoading = true;
        if (!this.user.blockbee_polygon_usdt) {
          const res = await AccountService.createBlockbeeAddressPolygon();
          console.log(res)
        }
        this.paymentPolygon = true;
        this.closeDepositModal()
        this.payment = false
        this.paymentUsdt = false
        this.paymentBep20 = false
        this.paymentTrc20 = false
      } catch (e) {
        console.log(e);
      } finally {
        this.blockbeePolygonAddressLoading = false;
      }
    },
    // async createUsdtPaymentRequest() {
    //   try {
    //     this.createUsdtPaymentRequestLoading = true;
    //     const res = await AccountService.createUsdtPaymentRequest(this.usdtDepositAmount);
    //     console.log(res);
    //     this.invoice = res.data.data;
    //     this.invoiceTimerCount = this.invoice.time_left;
    //     this.usdtDeposit = false;
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     this.createUsdtPaymentRequestLoading = false;
    //   }
    // },
    async selectInvoice(invoice) {
      try {
        const res = await AccountService.getUsdtInvoice(invoice.invoice_id);
        console.log(res);
        this.invoice = res.data.data;
        this.invoiceTimerCount = this.invoice.time_left;
        this.usdtDeposit = false;
      } catch (e) {
        console.log(e)
      }
    },
    async activateSubscription(plan) {
      try {
        this.activateSubLoading = true
        const res = await AccountService.activateSubscription(plan.id);
        console.log(res);
      } catch (e) {
        console.log(e)
      } finally {
        this.activateSubLoading = false
      }
    },
    togglePlan(plan) {
      this.selectedPlan = plan;
      if (this.expandedPlan === plan.id) {
        this.expandedPlan = null;
      } else {
        this.expandedPlan = plan.id;
      }
    },
    discountedPrice(plan) {
      return plan.price - (plan.price * (this.discount?.discountPercent / 100))
    },
    showPaymentRequiredModal() {
      this.paymentRequired = true;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.columns.account,
.columns.payment {
  height:auto;
}
button.card-header-icon.plan {
  border: none;
  background-color: transparent;
}
.card {
  color: var(--text-primary-color);

  .card-header {
    cursor: pointer;
  }
  .card-header-title {
    color: var(--text-tertiary-color);
  }
  span.icon {
    color: white;
  }
}
.card.account{
  height:100%;
}
.card.selected {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);

  .card-header-title {
    color: var(--text-primary-color);
  }
  span.icon {
    color: white;
  }
}
#transactions {
  background-color: var(--table-background-color);
  color: var(--table-content-color);
}
#transactions td {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.table {
  background-color: var(--app-background-color);
}

#transactions tr {
  border: 1px solid;
  border-color: var(--app-background-color);
  &:hover {
    background-color: var(--app-background-color);
    border: 1px solid;
    border-color: var(--table-background-color);
  }
}
.userEmail {
 display: block;
 .button {
  float: right;
 }
 }
.deposit {
  display: flex;
  justify-content: space-between;
  .deposit-buttons {
    gap: 10px
  }
}
</style>
